body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loginForm {
  -webkit-box-shadow: 1px 10px 73px 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 1px 10px 73px 0px rgba(0, 0, 0, 0.45);
  box-shadow: 1px 10px 73px 0px rgba(0, 0, 0, 0.45);
}

:root {
  --theme-color: #318567;
  --lightGray: #f8f8f8;

  --incoming: lightCyan;
  --outcoming: cornSilk;

  --draft: bisque;
  --waiting: cornSilk;
  --checking: honeyDew;
  --arrangement: azure;
  --inInventory: whiteSmoke;
  --unread: #e25f79;
}

/* @media screen and (max-width: 1366px) { */
select {
  border: 1px solid lightGray !important;
}
/* } */

/* table row color */
tbody.ant-table-tbody tr.incoming {
  background-color: var(--incoming) !important;
}
tbody.ant-table-tbody tr.outcoming {
  background-color: var(--outcoming) !important;
}

tbody.ant-table-tbody tr.unread {
  background-color: var(--unread) !important;
}
tbody.ant-table-tbody tr.draft {
  background-color: var(--draft) !important;
}
tbody.ant-table-tbody tr.waiting {
  background-color: var(--waiting) !important;
}
tbody.ant-table-tbody tr.checking {
  background-color: var(--checking) !important;
}
tbody.ant-table-tbody tr.arrangement {
  background-color: var(--arrangement) !important;
}
tbody.ant-table-tbody tr.inInventory {
  background-color: var(--inInventory) !important;
}
tbody.ant-table-tbody tr.inventoryHistory {
  background-color: var(--draft) !important;
}
tbody.ant-table-tbody tr.deliveryHistory {
  background-color: var(--incoming) !important;
}

header.ant-layout-header {
  border-top: 3px solid var(--theme-color);
}
thead.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  background-color: var(--theme-color);
}
thead.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background-color: var(--theme-color);
  opacity: 0.5;
}

header.header,
header.header div.ant-row,
header.header ul.ant-menu {
  background: white;
}

.App {
  padding-top: 115px;
}
header.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 68px;
  z-index: 100;
}
.gnav {
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  height: 48px;
  z-index: 100;
}
main h2 {
  color: var(--theme-color);
  display: inline-block;
}

button.ant-btn {
  background-color: var(--theme-color);
  color: white;
  margin: 10px 50px;
  padding: 5px 50px;
}

button.ant-btn.btn-white {
  border-color: var(--theme-color);
  background-color: white;
  color: var(--theme-color);
}

button.ant-btn.btn-white:hover {
  cursor: pointer;
  border-color: #1890ff;
  background-color: white;
  color: #1890ff;
}

button.ant-btn.btn-white[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.lower-action-button button.ant-btn{
  margin: 10px 20px;
  padding: 5px 30px;
}

div.ant-table-body button.ant-btn {
  background-color: var(--theme-color);
  color: white;
}

div.ant-table-body a {
  color: var(--theme-color);
  text-decoration: underline;
}
ul.ant-pagination li {
  border-color: var(--theme-color);
}
ul.ant-pagination li a {
  color: var(--theme-color);
}
main h2 {
  margin-bottom: 50px;
}

div.listTable table th {
  background-color: #fff;
}
div.listTable table tr th,
table tr td,
div.listTable table tr td {
  padding: 5px 0 5px 5px;
  margin: 0;
  height: 30px;
}
div.listTable table tr.ant-table-row :hover {
  cursor: pointer;
}

/* div.tableArea div.ant-table-body {
  margin-top: 20px;
} */
div.tableArea table th {
  background-color: var(--theme-color);
  color: #fff;
  /* padding: 5px 0 5px 20px; */
  padding: 5px 0 5px 10px;
  font-size: 11px;
  letter-spacing: 0;
  white-space: nowrap;
}
div.tableArea table p,
div.ant-row p {
  margin: 0;
}
div.tableArea .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  margin-left: 2px !important;
  margin-top: -6px;
}
div.tableArea .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 12px !important;
}
div.tableArea .ant-table-thead > tr > th .anticon-filter,
div.tableArea .ant-table-thead > tr > th .ant-table-filter-icon {
  width: 12px;
}
a.anchor {
  color: var(--theme-color);
  text-decoration: underline;
}
a.iconColorReverse i svg {
  background-color: var(--theme-color);
  color: #fff;
  margin-right: 5px;
  border-radius: 1px;
}
.themeColor {
  color: var(--theme-color);
}

div.ant-modal-confirm-btns button.ant-btn {
  margin: 0;
  background-color: transparent;
  border: none;
  color: lightGray;
  text-decoration: underline;
}
div.ant-modal-confirm-btns button.ant-btn-primary {
  margin: 0;
  background-color: var(--theme-color);
}
div.ant-modal-confirm-body i {
  display: none;
}
div.ant-modal-confirm-content {
  margin: 0 !important;
}
tbody.ant-table-tbody > tr:nth-child(2n + 1),
div.tableArea table > tr:nth-child(2n + 1),
div.billTable table > tr:nth-child(2n + 1) {
  background-color: var(--lightGray);
}
div.tableArea tbody.ant-table-tbody tr,
div.tableArea tbody.ant-table-tbody td {
  padding: 0 10px;
}
tbody.ant-table-tbody > tr > td {
  border: none;
}

div.ant-col p.leftUpSmall {
  float: left;
  font-size: 7px;
  color: gray;
  margin-left: 10px;
}
div.ant-drawer-content p.leftUpSmall {
  margin: 0;
}

div.ant-drawer-content button.ant-drawer-close {
  left: 0;
}

span.ant-cascader-picker input {
  border: none;
}
span.ant-cascader-picker {
  background-color: transparent;
}

input.sellingPrice {
  width: 200px;
}
input.sellingAmount {
  width: 100px;
}

.projectTable div.ant-tabs-nav-scroll {
  float: right;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: auto;
  overflow-x: unset !important;
}

/* 基本情報 */
.basicInfo > .ant-row > .ant-col {
  margin-right: 20px;
  margin-left: 0;
  min-width: 300px;
}

.basicInfo > .ant-row > .ant-col .ant-input-number,
.basicInfo > .ant-row > .ant-col .ant-calendar-picker {
  width: 100%;
}

/* 発注一覧 CheckBox */
.order-list-supplier .ant-table-tbody > tr > td.ant-table-selection-column .ant-checkbox-input{
	-moz-transform:		scale(2.8);
	-webkit-transform:	scale(2.8);
	transform:		scale(2.8);
}